<template>
  <div
    class="employee"
    @click="edit = !edit"
  >
    <div class="fio">
      <div v-if="!edit">{{user.name}}</div>
      <v-text-field
        v-else
        placeholder="Введите ФИО"
        v-model="getUserName"
        class="ml-2 rounded-lg"
        hide-details
        background-color="white"
        outlined
        dense
        @click.stop=""
      ></v-text-field>
    </div>

    <div class="position">
      <div v-if="!edit">{{user.position}}</div>
      <v-select
        class="positionEdit"
        v-else
        v-model="getTableFiltersPosition"
        :items="getFiltersPositions"
        label="Должность"
        append-icon="mdi-chevron-down"
        hide-details
        background-color="#fff"
        color="#AAAAAA"
        item-value="value"
        item-text="value"
        solo
        dense
        flat
        outlined
        @click.stop=""
      ></v-select>
    </div>

    <div class="role">
      <div v-if="!edit">{{ printUserRoles(user.roles) }}</div>
      <v-select
        class="positionEdit"
        v-else
        v-model="getUserRoles"
        :items="roles.map(({ id, name }) => ({ name, value: id }))"
        label="Роль в системе"
        append-icon="mdi-chevron-down"
        hide-details
        background-color="#fff"
        color="#AAAAAA"
        item-value="value"
        item-text="name"
        solo
        dense
        flat
        outlined
        multiple
        @click.stop=""
      ></v-select>
    </div>

    <div
      class="price"
      v-if="tableIsOutsource"
    >
      <div v-if="!edit">{{user.payment_per_hour}}</div>
      <v-text-field
        v-else
        v-model="getUserPrice"
        class="ml-2 rounded-lg"
        hide-details
        background-color="white"
        outlined
        dense
        @click.stop=""
      ></v-text-field>
    </div>

    <div class="email">
      <div v-if="!edit">{{user.email}}</div>
      <v-text-field
        v-else
        placeholder="Введите email"
        v-model="getUserEmail"
        class="ml-2 rounded-lg"
        hide-details
        background-color="white"
        outlined
        dense
        @click.stop=""
      ></v-text-field>
    </div>

    <div class="office">
      <div v-if="!edit">{{officeNormalize(user.office)}}</div>
      <v-select
        v-else
        v-model="getTableFiltersOffice"
        :items="OFFICES"
        :label="officeNormalize(user.office)"
        append-icon="mdi-chevron-down"
        hide-details
        background-color="#fff"
        color="#AAAAAA"
        item-value="value"
        item-text="value"
        dense
        solo
        flat
        outlined
        @click.stop=""
      ></v-select>
    </div>

    <div class="status">
      <v-select
        @click.stop=""
        class="statusColor"
        :class="{'statusColorActive': user.is_active === true}"
        flat
        @change="changeUserStatus($event)"
        :items="statuses"
        :label="user.is_active ? 'Активный': 'Неактивный'"
        append-icon="mdi-chevron-down"
        hide-details
        background-color="#fff"
        color="red"
        rounded
        dense
        outlined
        solo
        item-value="id"
        item-text="value"
      ></v-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { OFFICES } from '@/core/constants/offices';
import { officeNormalize } from '@/core/helpers';

export default {
  name: 'Employee',

  props: {
    user: {
      type: Object,
      require: true,
    },
    tableIsOutsource: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      edit: false,
      statuses: [{ id: true, value: 'Активный' }, { id: false, value: 'Неактивный' }],
      OFFICES,
    };
  },

  computed: {
    ...mapState(['filters']),
    ...mapState('roles', ['roles']),

    getUserName: {
      get() {
        return this.user.name;
      },
      set(val) {
        this.user.name = val;
        this.changeEmployee();
      },
    },

    getUserPrice: {
      get() {
        return this.user.payment_per_hour;
      },
      set(val) {
        this.user.payment_per_hour = val;
        this.changeEmployee();
      },
    },

    getUserEmail: {
      get() {
        return this.user.email;
      },
      set(val) {
        this.user.email = val;
        this.changeEmployee();
      },
    },

    getUserRoles: {
      get() {
        return this.user.roles.filter((f) => f).map((m) => {
          if (m.id) return m.id;
          return m.value;
        });
      },
      set(val) {
        // this.user.roles = val.filter((f) => f);
        this.user.roles = val.map((m) => this.roles.find((f) => f.id === m));
        this.changeEmployee();
      },
    },

    getTableFiltersPosition: {
      get() {
        return this.user.position;
      },
      set(val) {
        this.user.position = val;
        this.changeEmployee();
      },
    },

    getFiltersPositions() {
      return this.filters?.positions;
    },

    getTableFiltersOffice: {
      get() {
        return this.user.office;
      },
      set(val) {
        this.user.office = val;
        this.changeEmployee();
      },
    },
  },

  methods: {
    ...mapActions('staff', ['putManagers']),

    officeNormalize,
    changeEmployee: debounce(function deb() {
      const roles = this.user.roles.filter((f) => f).map((m) => (m.id ? m.id : m.value));
      this.putManagers({
        ...this.user,
        roles,
        position: this.getPosition(this.user.position),
      });
    }, 300),

    printUserRoles(roles) {
      return roles.map((m) => m.name).join(', ');
    },

    changeUserStatus(val) {
      this.user.is_active = val;
      this.changeEmployee();
    },

    getPosition(pos) {
      return this.filters.positions.find((el) => el.value === pos).id;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

.employee {
  display: flex;
  width: auto;
  .fio,
  .position,
  .price,
  .email,
  .role,
  .office,
  .status {
    color: #5a5a5a;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-right: 1px solid #d6d6d6;
    ::placeholder {
      color: #5a5a5a;
    }
  }
  ::v-deep .v-select__selection {
    color: #5a5a5a;
  }
  .fio {
    min-width: 300px;
  }
  .position {
    min-width: 150px;
  }
  .price {
    min-width: 110px;
  }
  .email {
    min-width: 300px;
  }
  .role {
    min-width: 300px;

    div {
      word-break: break-all;
    }
  }
  .office {
    min-width: 150px;
  }
  .status {
    border-right: none;
    min-width: 200px;
  }
}

.val {
  width: auto !important;
  min-width: fit-content !important;
  border-top: 1px solid #d6d6d6;
  cursor: pointer;
  &:hover {
    background: #eff1f2;
  }
}

.statusColor {
  ::v-deep fieldset,
  ::v-deep .v-select__slot {
    border-color: #aaaaaa !important;
    color: #aaaaaa !important;
    i,
    label,
    .v-select__selection {
      color: #aaaaaa !important;
    }
  }
}
.statusColorActive {
  ::v-deep fieldset,
  ::v-deep .v-select__slot {
    border-color: #6bac5a !important;
    color: #6bac5a !important;

    i,
    label,
    .v-select__selection {
      color: #6bac5a !important;
    }
  }
}
</style>
