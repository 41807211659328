<template>
  <div class="employees">

    <div class="top">
      <span
        @click="$router.push('/staff')"
        class="active"
      >Сотрудники</span>
      <span> / Все сотрудники</span>
    </div>

    <div class="header">
      <div class="left">
        <div
          class="back"
          @click="$router.push('/staff')"
        >
          <v-icon color="#1976D2">mdi-chevron-left</v-icon>
        </div>
        <div class="tit">Все сотрудники</div>
      </div>

      <div class="rigth">

        <v-btn
          color="black"
          dark
          class="radius-root"
          x-large
          @click="changeAddStaffModal(true)"
        >
          <v-icon class="mr-3 pointer">mdi-account-multiple-plus</v-icon>
          Добавить сотрудника
        </v-btn>

        <AddStaffModal
          :isAddStaffModal="isAddStaffModal"
          @changeAddStaffModal="changeAddStaffModal"
        />
      </div>
    </div>

    <div class="btnToggle">
      <div
        @click="changeIsOutsource(false)"
        :class="{active: tableFilters.is_outsource === false}"
      >Сотрудники в штате</div>
      <div
        @click="changeIsOutsource(true)"
        :class="{active: tableFilters.is_outsource === true}"
      >Сотрудники аутстаф</div>
    </div>

    <div class="table">
      <div v-if="showLoader && managers.length > 0" class="table__loader">
        <Loader />
      </div>
      <div class="table__body">
        <div class="item header">
          <div class="fio">
            ФИО
            <v-icon
              v-if="tableFilters.name_sort === 'DESC'"
              @click="changeSort('ASC')"
              color="#AAAAAA"
              class="ml-1"
            >mdi-sort-alphabetical-descending-variant</v-icon>
            <v-icon
              v-else-if="tableFilters.name_sort === 'ASC'"
              @click="changeSort('DESC')"
              color="#AAAAAA"
              class="ml-1"
            >mdi-sort-alphabetical-ascending-variant</v-icon>
            <v-icon
              v-else
              @click="changeSort('DESC')"
              color="#AAAAAA"
              class="ml-1"
            >mdi-sort-alphabetical-ascending-variant</v-icon>

            <v-text-field
              placeholder="Введите ФИО"
              v-model="getFIO"
              class="ml-2 rounded-lg"
              hide-details
              background-color="white"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="position">
            <v-select
              flat
              v-model="getTableFiltersPosition"
              :items="getFiltersPositions"
              placeholder="Должность"
              append-icon="mdi-chevron-down"
              hide-details
              background-color="#EFF1F2"
              color="#AAAAAA"
              item-value="value"
              item-text="value"
              dense
              clearable
              solo
            ></v-select>
          </div>

          <div class="role">
            <v-select
              multiple
              flat
              v-model="selectedRoles"
              :items="roles"
              placeholder="Роль в системе"
              append-icon="mdi-chevron-down"
              hide-details
              background-color="#EFF1F2"
              color="#AAAAAA"
              item-value="id"
              item-text="name"
              dense
              clearable
              solo
            ></v-select>
          </div>

          <div v-if="tableFilters.is_outsource" class="price">Зп/час, ₽</div>

          <div class="email">Email
            <v-text-field
              placeholder="Введите email"
              v-model="getEmail"
              class="ml-2 rounded-lg"
              hide-details
              background-color="white"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="office">
            <v-select
              flat
              v-model="getTableFiltersOffice"
              :items="offices"
              placeholder="Офис"
              append-icon="mdi-chevron-down"
              hide-details
              background-color="#EFF1F2"
              color="#AAAAAA"
              item-value="id"
              item-text="value"
              dense
              clearable
              solo
            ></v-select>
          </div>
          <div class="status">
            <v-select
              flat
              v-model="getTableFiltersStatus"
              :items="statuses"
              placeholder="Статус аккаунта"
              append-icon="mdi-chevron-down"
              hide-details
              background-color="#EFF1F2"
              color="#AAAAAA"
              item-value="id"
              item-text="value"
              dense
              clearable
              solo
            ></v-select>
          </div>
        </div>
        <Employee
          class="item val"
          v-for="(user,index) in filteredManagers"
          :key="index"
          :user="user"
          :tableIsOutsource="tableFilters.is_outsource"
        />
        <NoResultsTable
          v-if="!showLoader && filteredManagers.length === 0 && (
            tableFilters.name_sort
            || tableFilters.is_outsource
            || tableFilters.name
            || tableFilters.position
            || tableFilters.email
            || tableFilters.office
            || tableFilters.is_active
            || tableFilters.payment_per_hour
            || selectedRoles.length > 0)"
          :showLabel="true"
          :labelText="'Ничего не найдено!'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import Loader from '@/components/UI/Loader.vue';
import Employee from '@/views/staff/components/Employee.vue';
import AddStaffModal from '@/views/staff/components/AddStaffModal.vue';
import { OFFICES } from '@/core/constants/offices';
import NoResultsTable from '@/components/UI/NoResultsTable.vue';

export default {
  name: 'Employees',
  components: {
    Employee, AddStaffModal, Loader, NoResultsTable,
  },
  data() {
    return {
      selectedRoles: [],
      isAddStaffModal: false,
      tableFilters: {
        name_sort: null,
        is_outsource: false,
        name: null,
        position: null,
        email: null,
        office: null,
        is_active: null,
        payment_per_hour: null,
      },
      statuses: [{ id: true, value: 'Активный' }, { id: false, value: 'Неактивный' }],
      offices: OFFICES,
      showLoader: true,
    };
  },
  computed: {
    ...mapState(['filters']),
    ...mapState('staff', ['managers']),
    ...mapState('roles', ['roles']),

    filteredManagers() {
      if (this.selectedRoles.length > 0) {
        return this.managers
          .filter((f) => f.roles.map((m) => (m.value || m.id))
            .some((r) => this.selectedRoles.includes(r)));
      }
      return this.managers;
    },

    getFIO: {
      get() {
        return this.tableFilters.name;
      },
      set(val) {
        this.tableFilters.name = val;
        this.getTable();
      },
    },

    getTableFiltersPosition: {
      get() {
        return this.tableFilters.position;
      },
      set(val) {
        this.tableFilters.position = val;
        this.getTable();
      },
    },

    getTableFiltersOffice: {
      get() {
        return this.tableFilters.office;
      },
      set(val) {
        this.tableFilters.office = val;
        this.getTable();
      },
    },

    getTableFiltersStatus: {
      get() {
        return this.tableFilters.is_active;
      },
      set(val) {
        this.tableFilters.is_active = val;
        this.getTable();
      },
    },

    getFiltersPositions() {
      return this.filters?.positions;
    },

    getEmail: {
      get() {
        return this.tableFilters.email;
      },
      set(val) {
        this.tableFilters.email = val;
        this.getTable();
      },
    },
  },
  methods: {
    ...mapActions(['getFilters']),
    ...mapActions('staff', ['getManagers', 'putManagers']),
    ...mapActions('roles', ['getRoles']),

    getTable: debounce(async function deb() {
      await this.getManagers({ ...this.tableFilters });
      this.showLoader = false;
    }, 300),

    changeIsOutsource(val) {
      this.showLoader = true;
      this.tableFilters.is_outsource = val;
      this.getTable();
    },

    changeAddStaffModal(val) {
      this.isAddStaffModal = val;
    },

    changeSort(val) {
      this.tableFilters.name_sort = val;
      this.getTable();
    },
  },
  created() {
    const sortByRole = localStorage.getItem('sortByRole');
    if (sortByRole) {
      // eslint-disable-next-line radix
      this.selectedRoles = [parseInt(sortByRole)];
      localStorage.removeItem('sortByRole');
    }

    this.getRoles({ is_filter: true });
    this.getFilters();
    this.getTable();
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

.employees {
  padding: 24px 30px;
  display: flex;
  flex-direction: column;

  .top {
    .active {
      color: #1976d2;
      cursor: pointer;
    }
    span {
      color: #9e9e9e;
      font-size: 14px;
      letter-spacing: 0.25px;
    }
  }

  > .header {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      .back {
        display: flex;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        border: 1px solid #1976d2;
        cursor: pointer;
      }

      .tit {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-left: 15px;
      }
    }
  }

  .btnToggle {
    width: max-content;
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 30px;
    min-height: 46px;

    div {
      padding: 10px;
      color: #212121;
      cursor: pointer;
    }
    .active {
      color: #fff;
      background: #1976d2;
    }
  }

  .table {
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 10px;
    border: 1px solid #d6d6d6;
    position: relative;

    &__body {
      height: 100%;
      overflow-y: auto;
    }
    &__loader {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      opacity: .8;
      z-index: 10;
      display: flex;
      user-select: none;
    }

    .item {
      display: flex;
      width: fit-content;

      .fio,
      .position,
      .price,
      .email,
      .role,
      .office,
      .status {
        color: #5a5a5a;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        border-right: 1px solid #d6d6d6;
        white-space: nowrap;
        ::placeholder {
          color: #5a5a5a;
        }
      }
      ::v-deep .v-select__selection {
        color: #5a5a5a;
      }
      .fio {
        min-width: 300px;
      }
      .position {
        min-width: 150px;
      }
      .price {
        min-width: 110px;
      }
      .email {
        min-width: 300px;
      }

      .role {
        min-width: 300px;
      }
      .office {
        min-width: 150px;
      }
      .status {
        border-right: none;
        min-width: 200px;
      }
    }
    .header {
      background: #eff1f2;
      width: auto;
      min-width: fit-content;
      position: sticky;
      top: 0;
      z-index: 8;
    }
  }
}
</style>
